<template>
  <div>
    <div class="row" v-if="!loading">
      <div class="col-md-12">
        <a
          href="#"
          @click.prevent="
            send = 'email';
            show_modal = true;
          "
          class="btn btn-primary btn-sm float-end"
          >Composer Email</a
        >
        <a
          href="#"
          @click.prevent="
            send = 'sms';
            show_modal = true;
          "
          class="btn btn-primary btn-sm float-end me-2"
          >Composer SMS</a
        >
        <h4>Send Email / SMS ({{ users.length }})</h4>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th v-for="(header, i) in headers" :key="`header${i}`">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(line, i) in users" :key="`user-${i}`">
                    <td v-for="(header, j) in line" :key="`line-${i}${j}`">
                      {{ header }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading v-if="loading" />

    <modal :show="show_modal" @close="show_modal = false" width="large">
      <template slot="header">Send {{ send }}</template>
      <form @submit.prevent="submit">
        <div class="form-group" v-if="send == 'email'">
          <label>Email Subject</label>
          <input
            type="text"
            placeholder="Subject"
            v-model="email_subject"
            class="form-control"
          />
        </div>
        <div class="form-group" v-if="send == 'email'">
          <label>Email Content</label>
          <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
            v-model="email_content"
            :init="{
              height: 400,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help',
            }"
          />
        </div>
        <div class="form-group" v-if="send == 'sms'">
          <label>SMS Content</label>
          <textarea
            rows="5"
            class="form-control"
            v-model="sms_content"
            placeholder="SMS Content"
          ></textarea>
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      users: [],
      send: "email",
      show_modal: false,
      email_content: "",
      sms_content: "",
      email_subject: "",
      headers: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/data/excel/${this.id}`)
        .then((response) => {
          this.users = response.data.data;
          this.headers = response.data.headers;
          this.loading = false;
        });
    },

    submit() {
      let data = {
        send: this.send,
        subject: this.email_subject,
        content: this.send == "email" ? this.email_content : this.sms_content,
      };

      this.$axios.post(`/api/v1/admin/data/excel/${this.id}`, data).then(() => {
        this.$swal("Success", "Success", "success");
        this.show_modal = false;
      });
    },
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
